import Spacing from './Spacing';
import HR from './HR';
import SpaceBetween from './SpaceBetween';
import FlexSpace from './FlexSpace';

export default {
  Spacing,
  HR,
  SpaceBetween,
  FlexSpace,
};
